/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import { render } from 'lit';
import {
  Block,
  Step,
  RenderContext,
  StepData,
  Theme,
  ScreenSize,
  Disclaimer,
  BlockState,
  renderPopup,
  BLOCK_TYPES,
  CLOSE_BEHAVIORS,
  CONFIG_ONCLICK_ACTIONS,
  RESERVED_DATA_NAMES,
  SCREEN_SIZES,
  getFinalStyleRulesForBlock,
  STYLE_ELEMENT_TYPES,
  STYLE_RULE_NAMES,
  getFinalStyleRulesForPopup,
  OPT_IN_METHODS,
  PSOIN_MASKED_NUMBER_PREFIX,
  STEP_ROLES,
  PopupData,
  hasAction,
  hasRole,
} from '@stodge-inc/block-rendering';
import {
  collectedEmailEvent,
  configureSubscriptionEnvironment,
  sendAttributesEvent,
  setPopupCookie,
} from '../../../../helpers/events';
import {
  POPUP_HARD_CLOSE_STATE,
  POPUP_SOFT_CLOSE_STATE,
  POPUP_TRIGGER_TYPES,
  POPUP_UNIQUE_IMPRESSION_COOKIE_NAME,
  SHOPIFY_DISCOUNT_CODE_COOKIE_NAME,
} from '../../../../helpers/constants';
import {
  blockPopupOptIn,
  resendOTP,
  validateOTP,
} from '../../../../sdk/core/onsite-opt-in/service';

import {
  EngagementTracker,
  createEngagementTracker,
} from '../../../../helpers/engagementTracker';
import {
  resizeIframeForSoftClose,
  resizeIframeForFullScreen,
  showIframe,
  makeIframeVisible,
  hideIframe,
  setCookieOnParentDocument,
  requestFocusedElement,
  restoreFocusedElement,
  trapFocusInPopup,
  removePopupFocusTrap,
} from '../../../../helpers/iframe';
import { attemptAutoApplyFondueCashback } from '../../../helpers/fondue-helpers';
import popupContainerTemplate from '../components/popupContainer';
import teaserContainerTemplate from '../components/teaserContainer';
import {
  BLOCK_POPUP_CONTAINER_ID,
  CLOSE_POPUP_STEP_ID,
  POPUP_EVENT_TYPES,
} from '../constants';
import { getOtpVerifyErrors } from '../../../otpUtils';
import {
  ONE_TIME_PASSCODE_INPUT_GENERAL_ERROR,
  ONE_TIME_PASSCODE_INPUT_VALIDATION_ERROR,
} from '../../../constants';
import { submitFingerprintData } from '../../../../helpers/fingerprint';
import {
  BlockPopupStatuses,
  EmptySubmitDataResult,
  PhoneSubmitDataResult,
  PopupEventTypes,
  PopupState,
  PsoinEmailDataResult,
  PsoinMatchDataResult,
  SubmitDataHandlerResult,
} from '../types';
import { focusableElementsSelector } from '../../../../helpers/ui';
import {
  getStepData,
  validateStepData,
  submitAndRouteToStep,
} from '../utils/data';
import { getInitialPopupState } from '../utils/popup';
import { hasAnyNextStep, getNextStep } from '../utils/steps';
import { getOptInSource } from '../utils/environment';
import {
  confirmPrefilledEmail,
  confirmPrefilledPhoneNumber,
  postPopupEvent,
  searchMatchingSubscribers,
  verifyAgeGate,
  validateOTPPsoin,
} from '../utils/api';
import { getUniqueValuesByKeyDeep, timeoutCallbackWithPromise } from '../utils';
import { getTeaserOffset } from '../utils/teaser';
import { preloadImages } from '../../../../helpers/utils';
import {
  getFriendlyNameForBlockPopupStatus,
  popupStateStore,
} from '../../helpers/stateManager';
import {
  postCloseMessage,
  postOpenMessage,
  postSubscriberCreatedMessage,
  postSubmitMessageBlockPopup,
} from '../../../forms/integration';
import { applyOfferAndRouteTo } from './buttonActionHandlers/applyOfferAndRouteToStep';
import { BlockPopup } from '../../../../types/blockPopup';

type BlockPopupStateManagerArgs = {
  currentCountry: string | null;
  enabledFeatureFlags: string[];
  disclaimer: Disclaimer;
  origin: string;
  popup: BlockPopup;
  sessionId: string;
  shopId: number;
  status: string | null;
  subscriberId: string | null;
  theme: Theme;
  viewport: ScreenSize;
  uniqueImpressionCookies: Record<string, string> | null;
};

const AGE_GATE_TIMEOUT_DURATION = 5 * 1000;

/*
  This class represents basically the entire state of the popup.
  We determine here if we should show the popup, the teaser, or nothing. If we show the popup,
  we also determine which page to render, and we track all data that the user types into
  the popup here, among other things. If you need to make changes to how block popups function as a whole, you are almost
  certainly in the right place. If you need to make changes to how a specific block works, you
  probably want to look at the block's implementation file instead.
*/

export class BlockPopupStateManager {
  private previousState: PopupState | null;

  currentCountry: string | null;
  disclaimer: Disclaimer;
  enabledFeatureFlags: string[];
  engagementTracker: EngagementTracker;
  hasEmailPrefillEnabled: boolean;
  isLoading = false;
  lastFocusedIframeElementId: string | null = null;
  origin: string;
  popupContainer: HTMLDivElement | null = null;
  popup: BlockPopup;
  sessionId: string;
  shopId: number;
  state: PopupState;
  subscriberId: string | null;
  viewport: ScreenSize;
  uniqueImpressionCookies: Record<string, string> | null;

  constructor({
    currentCountry,
    disclaimer,
    enabledFeatureFlags,
    origin,
    popup,
    sessionId,
    shopId,
    status,
    subscriberId,
    viewport,
    uniqueImpressionCookies,
  }: BlockPopupStateManagerArgs) {
    this.currentCountry = currentCountry;
    this.disclaimer = disclaimer;
    this.enabledFeatureFlags = enabledFeatureFlags;
    this.engagementTracker = createEngagementTracker(() =>
      this.trackAnalyticsEvent(POPUP_EVENT_TYPES.ENGAGEMENT),
    );
    this.hasEmailPrefillEnabled = popup.stepBlocks.some(
      (s) =>
        s.type === BLOCK_TYPES.EMAIL_INPUT && s.config?.emailPrefillEnabled,
    );
    this.origin = origin;
    this.popup = popup;
    this.previousState = null;
    this.sessionId = sessionId;
    this.state = getInitialPopupState(popup, status);
    this.shopId = shopId;
    this.subscriberId = subscriberId;
    this.viewport = viewport;
    this.uniqueImpressionCookies = uniqueImpressionCookies;

    this.preloadSrcAndBgImgs(); // Only preloads the first step's images
    this.prepareTrigger();
  }

  setState(stateUpdates: Partial<PopupState>) {
    this.previousState = { ...this.state };
    this.state = {
      ...this.state,
      ...stateUpdates,
    };

    this.render();
  }

  setBlockState(blockId: string, newState: Partial<BlockState>) {
    const newBlockStateMap = { ...this.state.blockState };
    newBlockStateMap[blockId] = { ...newBlockStateMap[blockId], ...newState };
    this.setState({ blockState: newBlockStateMap });
  }

  setBlockErrors = (errors: Record<string, string | null>) => {
    const newBlockStateMap = { ...this.state.blockState };
    Object.entries(errors).forEach(([blockId, error]) => {
      newBlockStateMap[blockId] = {
        error,
        isResendOtpSuccessVisible: false,
        selected: false,
      };
    });
    this.setState({ blockState: newBlockStateMap });
    this.popupContainer
      ?.querySelector<HTMLElement>(`.block-${Object.keys(errors)[0]} input`)
      ?.focus();
  };

  /* If initializing preload stuff from just the first step in order to save
  bandwidth, otherwise try to preload from all blocks. */
  preloadSrcAndBgImgs() {
    const currentStep = this.popup.steps.find(
      (step) => step.id === this.state.currentStepId,
    );

    const blocks =
      currentStep?.order === 0
        ? // Different than blocks to render, which could just be teaser blocks
          this.popup.stepBlocks.filter(
            (block) => block.stepId === this.state.currentStepId,
          )
        : [...this.popup.stepBlocks];

    const finalStyles = getFinalStyleRulesForPopup({
      stepBlocks: blocks,
      teaserBlocks: [],
      theme: this.popup.theme,
      viewport: this.viewport,
    });

    const imageBlocks = blocks.filter(
      (block) => block.type === BLOCK_TYPES.IMAGE,
    );

    const urls = getUniqueValuesByKeyDeep({ ...finalStyles, imageBlocks }, [
      'src',
      STYLE_RULE_NAMES.BACKGROUND_IMAGE,
    ]) as string[];

    const formattedUrls = urls.map((url) => {
      // Shave off the 'url(' and ')' from background-image values
      const isBackgroundImage = url.startsWith('url(') && url.endsWith(')');
      return isBackgroundImage ? url.slice(4, -1) : url;
    });

    preloadImages(formattedUrls);
  }

  prepareTrigger() {
    const { type, config } = this.popup.trigger;

    if (type === POPUP_TRIGGER_TYPES.DELAY) {
      setTimeout(() => {
        this.render();
      }, config?.delay ?? 0);
    } else if (type === POPUP_TRIGGER_TYPES.CUSTOM) {
      this.render();
    }
  }

  get blocksToRender() {
    if (this.state.status === BlockPopupStatuses.TEASER) {
      return this.popup.teaserBlocks;
    }

    return this.popup.stepBlocks.filter(
      (block) => block.stepId === this.state.currentStepId,
    );
  }

  get currentStep() {
    return (
      this.popup.steps.find((step) => step.id === this.state.currentStepId) ??
      this.popup.steps[0]
    );
  }

  get renderContext(): RenderContext {
    return {
      blocks: this.blocksToRender,
      blockState: this.state.blockState,
      currentStep: this.currentStep,
      disclaimer: this.disclaimer,
      environment: {
        currentCountry: this.currentCountry ?? undefined,
        enabledFeatureFlags: this.enabledFeatureFlags,
        viewport: this.viewport,
      },
      popupActions: {
        getNode: (selector: string) => document.querySelector(selector),
        handleButtonClick: (block) => this.handleButtonClick(block),
        handleTeaserClick: () => this.setStatusToOpen(),
        hardClose: () => this.closePopup(true),
        resendOtp: (block: Block) => this.resendOtp(block),
        updatePopupData: (popupData) => {
          this.setState({
            popupData: {
              ...this.state.popupData,
              ...(popupData ?? {}),
              subscriberProperties: {
                ...this.state.popupData.subscriberProperties,
                ...(popupData?.subscriberProperties ?? {}),
              },
            },
          });
        },
        goBackToPhoneNumberStep: () => this.goBackToPhoneNumberStep(),
        updateStepData: (dataName, dataValue) =>
          this.updateStepData(dataName, dataValue),
      },
      popupData: this.state.popupData,
      stepData: this.state.stepData,
      sharedBlockConfig: this.popup.sharedBlockConfig,
      theme: this.popup.theme,
    };
  }

  resendOtp = async (block: Block) => {
    this.setBlockState(block.id, { isResendOtpSuccessVisible: false });

    const { success } = await resendOTP(
      this.shopId,
      this.state.popupData[RESERVED_DATA_NAMES.PHONE] as string,
      this.state.popupData.matchToken,
    );

    if (success)
      this.setBlockState(block.id, { isResendOtpSuccessVisible: true });
  };

  goBackToPhoneNumberStep = async () => {
    this.setState({
      popupData: {
        ...this.state.popupData,
        matchToken: undefined,
        phone: undefined,
      },
    });
    const phoneStep = this.popup.steps.find(
      (s) =>
        hasRole(s, STEP_ROLES.ONSITE_PHONE) ||
        hasRole(s, STEP_ROLES.PSN_ONSITE_PHONE),
    );
    if (phoneStep) this.routeToStep(phoneStep);
  };

  updateStepData = (dataName: string, dataValue: any) => {
    this.setState({
      stepData: {
        ...this.state.stepData,
        [dataName]: dataValue,
      },
    });
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setStatusToOpen() {
    this.setState({
      status: BlockPopupStatuses.OPEN,
    });
  }

  setStatusToClosed() {
    this.engagementTracker.endSession();
    setPopupCookie(POPUP_HARD_CLOSE_STATE, this.popup.id);
    this.setState({
      status: BlockPopupStatuses.CLOSED,
    });
    removePopupFocusTrap();
    restoreFocusedElement(this.lastFocusedIframeElementId ?? '');
    this.setPopupContainerInstance(null);
  }

  setStatusToTeaser() {
    this.engagementTracker.endSession();
    setPopupCookie(POPUP_SOFT_CLOSE_STATE, this.popup.id);
    this.setState({
      status: BlockPopupStatuses.TEASER,
    });
    removePopupFocusTrap();
    restoreFocusedElement(this.lastFocusedIframeElementId ?? '');
    this.setPopupContainerInstance(null);
  }

  startEngagementTracker() {
    this.engagementTracker.startSession(this.popupContainer);
  }

  closePopup(forceHardClose = false) {
    const isEndOfPopup = forceHardClose
      ? true
      : !hasAnyNextStep({
          currentStepId: this.state.currentStepId,
          stepBlocks: this.popup.stepBlocks,
          steps: this.popup.steps,
        });
    const shouldHardClose =
      forceHardClose ||
      isEndOfPopup ||
      this.popup.closeBehavior === CLOSE_BEHAVIORS.HARD_CLOSE;

    if (shouldHardClose) {
      this.setStatusToClosed();
    } else {
      this.setStatusToTeaser();
    }

    postCloseMessage(this.popup.id, this.popup.name, shouldHardClose);
  }

  changeLocation(block: Block) {
    const { href } = block.config?.onClick ?? {};

    const isEndOfPopup = !hasAnyNextStep({
      currentStepId: this.state.currentStepId,
      stepBlocks: this.popup.stepBlocks,
      steps: this.popup.steps,
    });

    if (isEndOfPopup) this.closePopup(true);
    if (href) window.setParentLocation(href);
  }

  routeToStep = (step: Step, validatedStepData?: StepData) => {
    if (step.id === CLOSE_POPUP_STEP_ID) {
      this.closePopup(true);
    }

    const newStepData = getStepData(this.popup, this.state.popupData, step.id);

    this.setState({
      currentStepId: step.id,
      stepData: newStepData,
      popupData: {
        ...this.state.popupData,
        ...(validatedStepData ?? {}),
        subscriberProperties: {
          ...this.state.popupData.subscriberProperties,
          ...(validatedStepData?.subscriberProperties ?? {}),
        },
      },
    });
  };

  // TODO(spin): address reduced motion
  animateSpinToWin(): Promise<void> {
    const blockToSpin = this.blocksToRender.find(
      (b) => b.type === BLOCK_TYPES.SPIN_TO_WIN,
    );
    if (!blockToSpin) return Promise.resolve();

    const duration = parseInt(
      getFinalStyleRulesForBlock(
        blockToSpin,
        this.popup.theme,
        this.viewport,
      )?.[STYLE_ELEMENT_TYPES.BLOCK]?.[STYLE_RULE_NAMES.SPIN_TO_WIN_DURATION] ??
        '1000ms',
      10,
    );

    this.setBlockState(blockToSpin.id, { isSpinning: true });

    return timeoutCallbackWithPromise(duration, () => {
      this.setBlockState(blockToSpin.id, { isSpinning: false });
    });
  }

  processStepErrors = async (): Promise<PopupData | undefined> => {
    const { data, errors } = validateStepData(
      this.blocksToRender,
      this.state.stepData,
    );

    if (Object.keys(errors).length > 0) {
      this.setBlockErrors(errors);
      this.setIsLoading(false);
      return;
    }

    const ageGateId = this.blocksToRender.find(
      (block) => block.type === BLOCK_TYPES.AGE_GATE_INPUT,
    )?.id;

    if (!ageGateId) {
      return data;
    }

    // Coalescing isn't strictly necessary here as the validation should catch missing age gate data. This is just for type safety.
    const age = new Date(
      this.state.stepData[ageGateId] ?? new Date().toUTCString(),
    );
    const ageGateResponse = await verifyAgeGate(this.shopId, age);
    if (!ageGateResponse) {
      this.setBlockErrors({
        [ageGateId]: 'Unable to verify submitted age. Please try again later.',
      });
      this.setIsLoading(false);
      return;
    }
    if (!ageGateResponse.success) {
      this.setBlockErrors({
        [ageGateId]: `You must be at least ${
          ageGateResponse.minAge
        } years old to subscribe. The dialog will dismiss in ${Math.round(
          AGE_GATE_TIMEOUT_DURATION / 1000,
        )} seconds.`,
      });

      const dismissUnderagePopup = () => {
        this.closePopup(true);
        this.setIsLoading(false);
      };

      setTimeout(dismissUnderagePopup, AGE_GATE_TIMEOUT_DURATION);
      return;
    }

    return data;
  };

  handleButtonClick = async (buttonBlock: Block) => {
    if (this.isLoading) return;
    this.setIsLoading(true);

    const persistAttributesFn = async (
      attrs: StepData,
    ): Promise<SubmitDataHandlerResult<EmptySubmitDataResult>> => {
      try {
        if (Object.keys(attrs).length) {
          await sendAttributesEvent({
            popup_id: this.popup.id,
            popup_type: 'BLOCK',
            shop_id: this.shopId,
            source: getOptInSource(this.viewport),
            session_id: this.sessionId,
            subscriber_id: (window as any).ps__subscriber_id,
            token: (window as any).ps__token,
            server_id: (window as any).ps__server_id,
            ...attrs,
          });
        }
      } finally {
        const email = attrs[RESERVED_DATA_NAMES.EMAIL];

        if (email) {
          (window as any).ps__email = email;
          collectedEmailEvent(email);
          this.trackAnalyticsEvent(POPUP_EVENT_TYPES.SUBMIT_EMAIL);
        }

        // eslint-disable-next-line no-unsafe-finally
        return {
          hasError: false,
          data: {},
        };
      }
    };

    if (hasAction(buttonBlock, CONFIG_ONCLICK_ACTIONS.CHANGE_LOCATION)) {
      const { data } = validateStepData([buttonBlock], this.state.stepData);

      /* Await subscriber endpoint resolve before changing location. The
      function resolves regardless of fullfillment or rejection, so it
      essentially indicates that the call was attempted. */
      await persistAttributesFn(data.subscriberProperties);

      postSubmitMessageBlockPopup({
        popupId: this.popup.id,
        popupName: this.popup.name,
        data,
      });

      this.changeLocation(buttonBlock);
      return;
    }

    if (hasAction(buttonBlock, CONFIG_ONCLICK_ACTIONS.CLOSE)) {
      const { data } = validateStepData([buttonBlock], this.state.stepData);

      // Optimistically unawaited to help the popup close feel natural
      persistAttributesFn(data.subscriberProperties);

      postSubmitMessageBlockPopup({
        popupId: this.popup.id,
        popupName: this.popup.name,
        data,
      });

      this.closePopup();
      this.setIsLoading(false);

      // UTM params on close are added to the href as a partial URL (e.g. ?utm_campaign=example)
      // This is slightly overriding the initial intent of `onClick.href`, but it does align with the overall result.
      const href = buttonBlock.config?.onClick?.href;
      if (href) {
        window.setParentLocation(href);
      }
      return;
    }

    if (hasAction(buttonBlock, CONFIG_ONCLICK_ACTIONS.ROUTE_TO_STEP)) {
      const data = await this.processStepErrors();
      if (!data) {
        return;
      }

      const nextStep = getNextStep({
        block: buttonBlock,
        currentStepId: this.state.currentStepId,
        steps: this.popup.steps,
      });

      // Optimistically unawaited
      persistAttributesFn(data.subscriberProperties);

      postSubmitMessageBlockPopup({
        popupId: this.popup.id,
        popupName: this.popup.name,
        data,
      });

      await this.animateSpinToWin();
      if (nextStep) this.routeToStep(nextStep);
      this.setIsLoading(false);
      return;
    }

    if (
      hasAction(buttonBlock, CONFIG_ONCLICK_ACTIONS.SUBMIT_AND_ROUTE_TO_STEP)
    ) {
      const validatedStepData = await this.processStepErrors();
      if (!validatedStepData) {
        return;
      }

      const spinToWinPromise = this.animateSpinToWin();

      const optInFn = async (
        phone: string,
      ): Promise<SubmitDataHandlerResult<PhoneSubmitDataResult>> => {
        const { id: phoneBlockId } =
          this.blocksToRender.find(
            ({ type }) => type === BLOCK_TYPES.PHONE_INPUT,
          ) ?? {};
        if (!phoneBlockId) throw new Error();

        const { subscriberId, success } = await blockPopupOptIn({
          country: this.currentCountry,
          phoneNumber: phone,
          popupId: this.popup.id,
          sessionId: this.sessionId,
          shopId: this.shopId,
          source: getOptInSource(this.viewport),
          sourceKey: this.popup.sourceKey,
        });
        const hasGeneralError = success === false;

        if (hasGeneralError) {
          this.setBlockErrors({
            [phoneBlockId]: ONE_TIME_PASSCODE_INPUT_GENERAL_ERROR,
          });
        } else {
          this.setBlockState(phoneBlockId, {
            error: null,
            isResendOtpSuccessVisible: false,
          });
        }

        return {
          hasError: hasGeneralError,
          data: {
            subscriberId,
            isExistingSubscriber: !!subscriberId,
          },
        };
      };

      const verifyOtpFn = async (
        otp: string,
      ): Promise<SubmitDataHandlerResult<PsoinEmailDataResult>> => {
        const phone = this.state.popupData[RESERVED_DATA_NAMES.PHONE];
        const { matchToken } = this.state.popupData;
        const { id: otpBlockid } =
          this.blocksToRender.find(
            ({ type }) => type === BLOCK_TYPES.OTP_INPUT,
          ) ?? {};
        if (!otpBlockid) throw new Error();

        // I don't love this, but validateOTP lives in sdk/core and it feels worse to put a switch there to hit a different endpoint
        const verificationFn = this.hasEmailPrefillEnabled
          ? validateOTPPsoin
          : validateOTP;
        const verifyOtpResponse = await verificationFn(
          this.shopId,
          matchToken ? undefined : phone,
          otp,
          this.popup.sourceKey,
          matchToken,
          // If there's no match token that means the user is not from the opt-in network and we want to track them as standard OOI instead
          matchToken
            ? this.popup.optInMethod
            : OPT_IN_METHODS.ONE_TIME_PASSCODE,
        );

        const { general, verification } = getOtpVerifyErrors(verifyOtpResponse);
        const hasError = !!general || !!verification;

        if (hasError || !verifyOtpResponse?.data) {
          if (general)
            this.setBlockErrors({
              [otpBlockid]: ONE_TIME_PASSCODE_INPUT_GENERAL_ERROR,
            });

          if (!general && verification)
            this.setBlockErrors({
              [otpBlockid]: ONE_TIME_PASSCODE_INPUT_VALIDATION_ERROR,
            });
        } else {
          const {
            cashback_utm_code: cashBackUtmCode,
            coupon_code: couponCode,
            subscriber_id: subscriberId,
            email,
          } = verifyOtpResponse.data;
          const hasAutoApplyOfferEnabled = this.popup.autoApplyOfferEnabled;

          this.setBlockErrors({ [otpBlockid]: null });

          configureSubscriptionEnvironment({
            subscriberId: +subscriberId,
          });

          submitFingerprintData(
            true,
            this.shopId?.toString(),
            subscriberId.toString(),
          );

          postSubscriberCreatedMessage(
            this.popup.id,
            this.popup.name,
            subscriberId,
            couponCode,
            cashBackUtmCode,
            hasAutoApplyOfferEnabled,
          );

          if (hasAutoApplyOfferEnabled && cashBackUtmCode)
            await attemptAutoApplyFondueCashback(cashBackUtmCode);

          if (hasAutoApplyOfferEnabled && couponCode)
            setCookieOnParentDocument(
              SHOPIFY_DISCOUNT_CODE_COOKIE_NAME,
              couponCode,
            );

          if (email) {
            this.setState({
              popupData: {
                ...this.state.popupData,
                subscriberProperties: {
                  ...this.state.popupData.subscriberProperties,
                  // We store the email in two separate places
                  // "email" is needed so we can prefill the input
                  // "emailNetworkMatch" is needed so we can confirm that the input value matches the network value
                  email,
                  emailNetworkMatch: email,
                },
              },
            });
          }
        }

        return {
          hasError,
          data: {
            email: verifyOtpResponse?.data?.email,
          },
        };
      };

      const searchOptInNetwork = async (
        email: string,
      ): Promise<SubmitDataHandlerResult<PsoinMatchDataResult>> => {
        if (
          this.popup.optInMethod !==
            OPT_IN_METHODS.OPT_IN_NETWORK_NO_PHONE_NUMBER &&
          this.popup.optInMethod !==
            OPT_IN_METHODS.OPT_IN_NETWORK_MASKED_PHONE_NUMBER
        ) {
          return Promise.resolve({
            hasError: false,
            data: { hasNetworkMatch: false },
          });
        }

        const result = await searchMatchingSubscribers(
          {
            country: this.currentCountry,
            popupId: this.popup.id,
            sessionId: this.sessionId,
            shopId: this.shopId,
            source: getOptInSource(this.viewport),
            sourceKey: this.popup.sourceKey,
            optInMethod: this.popup.optInMethod,
          },
          {
            email,
          },
        );

        if (result.matchToken) {
          this.setState({
            popupData: {
              ...this.state.popupData,
              phone:
                this.popup.optInMethod ===
                OPT_IN_METHODS.OPT_IN_NETWORK_MASKED_PHONE_NUMBER
                  ? `${PSOIN_MASKED_NUMBER_PREFIX}${result.phoneNumberLastFour}`
                  : this.state.popupData.phone,
              matchToken: result.matchToken,
              phoneNumberLastFour: result.phoneNumberLastFour,
            },
          });
        }

        return {
          hasError: false, // On error fall back to normal OOI flow
          data: {
            hasNetworkMatch:
              !!result.phoneNumberFirstSix || !!result.phoneNumberLastFour,
            matchToken: result.matchToken,
            phoneNumberFirstSix: result.phoneNumberFirstSix,
            phoneNumberLastFour: result.phoneNumberLastFour,
          },
        };
      };

      const confirmMaskedPhoneNumberFn = async (): Promise<
        SubmitDataHandlerResult<EmptySubmitDataResult>
      > => {
        const { matchToken } = this.state.popupData;

        if (
          !matchToken ||
          this.popup.optInMethod !==
            OPT_IN_METHODS.OPT_IN_NETWORK_MASKED_PHONE_NUMBER
        ) {
          return Promise.resolve({ hasError: false, data: {} });
        }

        await confirmPrefilledPhoneNumber(matchToken);

        return {
          hasError: false,
          data: {},
        };
      };

      const confirmNetworkEmail = async (): Promise<
        SubmitDataHandlerResult<EmptySubmitDataResult>
      > => {
        if (
          this.hasEmailPrefillEnabled &&
          validatedStepData.subscriberProperties.email ===
            this.state.popupData.subscriberProperties.emailNetworkMatch
        ) {
          confirmPrefilledEmail();
        }

        return {
          hasError: false,
          data: {},
        };
      };

      const { hasError, data } = await submitAndRouteToStep({
        confirmMaskedPhoneNumberFn,
        confirmNetworkEmail,
        optInFn,
        persistAttributesFn,
        validatedStepData,
        verifyOtpFn,
        searchOptInNetwork,
        matchToken: this.state.popupData.matchToken,
      });

      const nextStep = getNextStep({
        block: buttonBlock,
        currentStepId: this.state.currentStepId,
        steps: this.popup.steps,
        isExistingSubscriber: !!data?.isExistingSubscriber,
        hasOptInNetworkMatch:
          !!data?.hasNetworkMatch &&
          this.popup.optInMethod ===
            OPT_IN_METHODS.OPT_IN_NETWORK_NO_PHONE_NUMBER,
      });

      /* Wait for the spinner to stop before removing loading state so you can't
      resubmit the form while it's spinning */
      await spinToWinPromise;
      this.setIsLoading(false);

      if (!hasError) {
        postSubmitMessageBlockPopup({
          popupId: this.popup.id,
          popupName: this.popup.name,
          data: validatedStepData,
        });
        this.routeToStep(nextStep, validatedStepData);
      }
    }

    if (
      hasAction(
        buttonBlock,
        CONFIG_ONCLICK_ACTIONS.APPLY_OFFER_AND_ROUTE_TO_STEP,
      )
    ) {
      const validatedPopupData = await this.processStepErrors();
      if (!validatedPopupData) return;

      await applyOfferAndRouteTo({
        block: buttonBlock,
        persistAttributesFn,
        popup: this.popup,
        routeToStep: this.routeToStep,
        shopId: this.shopId,
        state: this.state,
        viewport: this.viewport,
      });
    }

    this.setIsLoading(false);
  };

  private trackAnalyticsEvent(eventType: PopupEventTypes) {
    const platform =
      this.viewport === SCREEN_SIZES.DESKTOP ? 'DESKTOP' : 'MOBILE';

    postPopupEvent(
      eventType,
      +this.shopId,
      this.popup.id,
      this.currentCountry,
      platform,
      this.popup.splitTest?.id,
    );
  }

  private setPopupContainerInstance(value?: null) {
    this.popupContainer =
      value === null
        ? null
        : document.querySelector(`#${BLOCK_POPUP_CONTAINER_ID}`);
  }

  private focusFirstElement() {
    this.popupContainer
      ?.querySelector<HTMLElement>(
        `:is(${focusableElementsSelector}):not([data-skip-initial-focus="true"], .iti__selected-country)`,
      )
      ?.focus({ preventScroll: true });
  }

  private renderHardClosed() {
    render(null, document.body);
  }

  private renderTeaser() {
    const teaserContent = renderPopup(this.renderContext);
    const teaserContainer = teaserContainerTemplate(teaserContent);
    render(teaserContainer, document.body);
  }

  private renderPopup() {
    const popupContent = renderPopup(this.renderContext);
    const popupContainer = popupContainerTemplate(
      popupContent,
      () => {
        this.closePopup();
      },
      this.currentStep.name,
    );
    render(popupContainer, document.body);
  }

  private prerender() {
    const isTeaserRendered =
      this.previousState?.status === BlockPopupStatuses.TEASER;
    const isPopupRendered =
      this.previousState?.status === BlockPopupStatuses.OPEN;
    const isIframeVisible = isTeaserRendered || isPopupRendered;

    const aboutToRenderPopup = this.state.status === BlockPopupStatuses.OPEN;
    const aboutToRenderTeaser = this.state.status === BlockPopupStatuses.TEASER;
    const aboutToClosePopup = this.state.status === BlockPopupStatuses.CLOSED;

    const aboutToRenderAnotherStep =
      this.previousState?.status === BlockPopupStatuses.OPEN &&
      this.state.status === BlockPopupStatuses.OPEN;

    if (!isIframeVisible && (aboutToRenderPopup || aboutToRenderTeaser)) {
      showIframe();
    }

    /* Attempts to preload all images from the popup, but any that have been
    previously preloaded in the constructor won't get requested again. */
    if (aboutToRenderAnotherStep) this.preloadSrcAndBgImgs();

    // Note that we also call resizeIframeForSoftClose in postRender. If the teaser is shown immediately
    // we need to set some initial sizing on the iframe, otherwise the button text will wrap and the call
    // in postRender will compute the size incorrectly.
    if (!this.previousState && aboutToRenderTeaser) {
      resizeIframeForSoftClose('', '', (_, teaserWidth) =>
        getTeaserOffset(this.popup, this.viewport, teaserWidth),
      ); // Empty strings so that the default teaser size is used
    }

    if (!isPopupRendered && aboutToRenderPopup) {
      requestFocusedElement((elementId: string | null) => {
        this.lastFocusedIframeElementId = elementId;
      });
      resizeIframeForFullScreen();
    }

    if (aboutToClosePopup) {
      hideIframe();
    }
  }

  private postrender() {
    const justRenderedAnotherStep =
      this.previousState?.status === BlockPopupStatuses.OPEN &&
      this.state.status === BlockPopupStatuses.OPEN &&
      this.previousState?.currentStepId !== this.state.currentStepId;
    const justRenderedPopup =
      this.previousState?.status !== BlockPopupStatuses.OPEN &&
      this.state.status === BlockPopupStatuses.OPEN;
    const justRenderedTeaser =
      this.previousState?.status !== BlockPopupStatuses.TEASER &&
      this.state.status === BlockPopupStatuses.TEASER;
    const wasPreviouslyTeaser =
      this.previousState?.status === BlockPopupStatuses.TEASER;
    const wasInitialRender = !this.previousState;
    const uniqueImpressionCookieName = `${POPUP_UNIQUE_IMPRESSION_COOKIE_NAME}${this.popup.id}`;
    const hasPreviousUniqueImpressionCookieSet =
      !!this.uniqueImpressionCookies?.[uniqueImpressionCookieName];

    // Rendered open from close, teaser, initial render
    if (justRenderedPopup) {
      this.setPopupContainerInstance();
      trapFocusInPopup();

      // When transitioning from teaser to open without a refresh, the unique impression cookie
      // is not detected due to stale state. This check ensures we avoid duplicate tracking.
      // In all other cases, we fire a regular IMPRESSION event.
      if (!hasPreviousUniqueImpressionCookieSet && !wasPreviouslyTeaser) {
        this.trackAnalyticsEvent(POPUP_EVENT_TYPES.UNIQUE_IMPRESSION);

        // Set the cookie after successfully tracking the event
        setCookieOnParentDocument(uniqueImpressionCookieName, 'true', 3650);
      } else {
        this.trackAnalyticsEvent(POPUP_EVENT_TYPES.IMPRESSION);
      }

      /* Focus first element after popup animates in on render. Only occurs for
      initial render or when transitioning from teaser to popup. */
      if (wasInitialRender || wasPreviouslyTeaser) {
        this.popupContainer?.addEventListener(
          'animationend',
          () => {
            this.focusFirstElement();
          },
          {
            once: true,
          },
        );
      }

      if (wasPreviouslyTeaser) {
        this.trackAnalyticsEvent(POPUP_EVENT_TYPES.ENGAGEMENT);
      } else {
        this.startEngagementTracker();
      }
      postOpenMessage(this.popup.id, this.popup.name, wasPreviouslyTeaser);
    }

    // Focus first element on steps other than initial render
    if (justRenderedAnotherStep) {
      setTimeout(() => {
        this.focusFirstElement();
      }, 100);
    }

    if (justRenderedPopup || justRenderedTeaser) {
      makeIframeVisible();
    }

    if (justRenderedTeaser) {
      const teaserRootId = this.popup.teaserBlocks.find(
        (b) => b.type === BLOCK_TYPES.TEASER_ROOT,
      )?.id;
      const teaserId = this.popup.teaserBlocks.find(
        (b) => b.type === BLOCK_TYPES.TEASER,
      )?.id;
      setTimeout(() => {
        resizeIframeForSoftClose(
          `teaser-${teaserId}`,
          `teaser-${teaserRootId}`,
          (_, teaserWidth) =>
            getTeaserOffset(this.popup, this.viewport, teaserWidth),
        );
      }, 0);
    }

    popupStateStore.updateState({
      id: this.popup.id,
      status: getFriendlyNameForBlockPopupStatus(this.state.status),
      engagementTracker: this.engagementTracker,
    });
  }

  render() {
    this.prerender();

    if (this.state.status === BlockPopupStatuses.CLOSED) {
      this.renderHardClosed();
    } else if (this.state.status === BlockPopupStatuses.TEASER) {
      this.renderTeaser();
    } else {
      this.renderPopup();
    }

    this.postrender();
  }
}
